<template lang="html">
  <aside class="sidebar">
    <div class="menu-group">
      <!-- 홈 -->
      <div class="menu-item">
        <router-link class="menu-link" :class="$route.meta.group === 'homePage' ? 'active' : ''" to="/main/home">
          <i class="ic-left-menu-home-01"></i><span class="menu-txt">홈</span>
        </router-link>
      </div>
      <!--// 홈 -->

      <!-- 대시보드 -->
      <div class="menu-item">
        <router-link class="menu-link" :class="$route.meta.group === 'dashboardPage' ? 'active' : ''" to="/main/dashboard">
          <i class="ic-left-menu-dash-01"></i><span class="menu-txt">대시보드</span>
        </router-link>
      </div>
      <!--// 대시보드 -->

      <!-- 단말기 -->
      <div>
        <div class="state-group">
          <div class="state-head">
            <p class="title">
              <span class="font-black text-white">단말기</span>
            </p>
            <div class="filter">
              <!-- <button type="button" class="element-btn" @click="initSideMenu()">
                <i class="icon-img ic-info-reload"></i>
              </button> -->

              <!-- <button type="button" class="element-btn bg-navy size-xs round">
                <i class="icon-img ic-menu-filter"></i>
                필터
                <i class="icon-img ic-filter-delete"></i>
              </button> -->

              <DropdownFilter></DropdownFilter>
            </div>
          </div>


          <ul  class="state-list" v-for="(sItem, idx) in siteList" :key="idx">
            <li>
              <button type="button" class="state-link" @click="dropdownSite(idx)" :class="selectedSite === idx ? 'active' : ''">
                <!-- <span class="link-txt">{{sItem.companyName}}</span> -->
                <span class="link-txt" :class="selectedSite === idx ? 'font-black' : 'font-light'" style="max-width:200px;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;">{{sItem.siteName}}</span>
                <i class="ic-menu-more"></i>
              </button>

              <ul>
                <li v-for="(mItem, jdx) in sItem.mapInfo" :key="jdx">
                  <button type="button" class="state-link" @click="dropdownMap(jdx)" :class="selectedMap === jdx ? 'active' : ''">
                    <span class="link-txt" :class="selectedMap === jdx ? 'font-black' : 'font-light'" style="max-width:180px;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;">{{mItem.mapName}}</span>
                    <i class="ic-menu-more"></i>
                  </button>

                  <ul>
                    <li v-for="(dItem, kdx) in mItem.deviceInfo" :key="kdx">
                      <a @click="moveDevice(dItem.deviceGuid, kdx)" class="state-link cursor-pointer">
                        <i :class="dItem.deviceStatus === 'regist' ? 'ic-state1' : 'ic-state2'"></i>
                        <span class="link-txt" :class="selectedDevice === kdx ? 'font-black' : dItem.deviceGuid === $route.query.guid ? 'font-black' : 'font-light'" style="max-width:160px;overflow-x:hidden;white-space:nowrap;text-overflow:ellipsis;"> {{dItem.deviceName}} : {{dItem.deviceID}} </span>
                        <!-- 신규가 있으면 표시 합니다. -->
                        <!-- <i class="badge-new">0</i> -->
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          
          
          
        </div>
      </div>
      <!--// 단말기 -->

      <!-- 설정 -->
      <div class="menu-item">
        <!-- <button type="button" class="menu-link has-menu" :class="{active: showSetting}" @click="dropdownSetting()"> -->
        <button type="button" class="menu-link has-menu setting-button" @click="dropdownSetting()">
          <i class="ic-left-menu-setting-01"></i>
          <span class="menu-txt">설정</span>
          <i class="ic-menu-more" style="transform:rotate(180deg);" v-if="showSetting"></i>
          <i class="ic-menu-more" v-else></i>
        </button>
        <div class="submenu-group" v-if="showSetting">
          <div v-if="getUserRole==='master'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingCompany' ? 'active' : ''" to="/main/setting/companylist">현장관리</router-link></div>
          <div class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingDevice' ? 'active' : ''" to="/main/setting/deviceadmin">단말기 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingMap' ? 'active' : ''" to="/main/setting/mapadmin">맵 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingManager' ? 'active' : ''" to="/main/setting/managerlist">계정 관리</router-link></div>
          <div v-if="getUserRole==='admin'" class="submenu-item"><router-link class="submenu-link" :class="$route.meta.group === 'settingSite' ? 'active' : ''" to="/main/setting/siteadmin">사이트 설정</router-link></div>
        </div>
      </div>
      <!--// 설정 -->
    </div>

    <!-- Log Out -->
    <div class="logout">
      <button type="button" class="btn-logout" @click="logoutAction()">
        <span class="btn-txt">Log Out</span>
        <i class="ic-logout-arrow"></i>
      </button>
    </div>
    <!--// Log Out -->
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { requestApi } from "@/utils/api"
import DropdownFilter from "@/components/elements/DropdownFilter"

export default {
  created () {
    this.initSideMenu()
  },
  components: {
    DropdownFilter,
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid","getDeviceFilterStatus","getIsShowEvent"])
    
  },
  data () {
    return {
      isShowAlarm: false,
      siteList: [],
      mapList: [],
      deviceList: [],
      selectedSite: -1,
      selectedMap: -1,
      selectedDevice: -1,
      showSetting: false,
      showSettingStr: '',
      deviceFilter: { normal: true, detect: true, offline: true },
    }
  },
  methods: {
    ...mapActions(["userLogoutAction","chgDeviceFilterStatus","chgIsShowEvent"]),
    async initSideMenu () {
      await this.getSiteList()
      await this.getMapList()
      await this.getDeviceList()

      for(let i=0; i<this.mapList.length; i++) {
        // let deviceInfo = this.deviceList.filter(x => x.mapGuid === this.mapList[i].mapGuid)
        // this.mapList[i].deviceInfo = deviceInfo
        this.mapList[i].deviceInfo = [];
        for(let j=0; j<this.deviceList.length; j++) {

          if(this.deviceList[j].mapGuid === this.mapList[i].mapGuid) {
            let pushDevice = false;
            if(this.deviceFilter.detect) {
              if(this.deviceList[j].detectCount > 0) pushDevice = true
            }

            if(this.deviceFilter.normal) {
              if(this.deviceList[j].deviceStatus === 'regist') pushDevice = true
            }

            if(this.deviceFilter.offline) {
              if(this.deviceList[j].deviceStatus === 'unregist' || this.deviceList[j].deviceStatus === 'unknown') pushDevice = true
            }

            if(pushDevice) this.mapList[i].deviceInfo.push(this.deviceList[j])
          }
        }
      }

      for(let i=0; i<this.siteList.length; i++) {
        let mapInfo = this.mapList.filter(x => x.siteGuid === this.siteList[i].siteGuid)
        this.siteList[i].mapInfo = mapInfo
      }

      console.log(this.siteList)
    },
    getShowSetting () {
      if(this.showSetting) return true
      return false;
    },
    dropdownSite (idx) {
      // this.selectedMap = -1
      if(this.selectedSite === idx) this.selectedSite = -1
      else this.selectedSite = idx
    },
    dropdownMap (idx) {
      this.selectedDevice = -1
      if(this.selectedMap === idx) this.selectedMap = -1
      else this.selectedMap = idx
    },
    dropdownSetting () {
      console.log("method act", this.showSetting, this.getSideExpand)
      // if(this.$route.path.substring(6,13) == 'setting') this.showSetting = true
      // else this.showSetting = false
      // console.log("#######################", this.showSetting)

      this.showSetting = !this.showSetting


      
      console.log("method act", this.showSetting)


      

      // console.log("#######################", this.showSetting)
    },

    moveDevice (guid, idx) {
      this.selectedDevice = idx
      this.$router.push({"name":"DefaultDevice","query":{guid}}).catch(()=>{});
    },

    logoutAction () {
      this.userLogoutAction();
      this.$router.push({name:"IndexPage"})
    },
    menuToggle() {
      console.log("make menu")
      const hasMenu = document.querySelectorAll('.has-menu');
      for (let i = 0; i < hasMenu.length; i++) {
        hasMenu[i].addEventListener('click', function() {
          this.classList.toggle('active');
        })
      }
    },

    async getSiteList () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteList = result.msg.retData;
      }
    },
    async getMapList () {
      let reqObj = {}
      reqObj.target = `/map/listall/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapList = result.msg.retData
      }
    },

    async getDeviceList () {
      let reqObj = {}
      reqObj.target = `/device/listsimple/${this.getCompanyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.deviceList = result.msg.retData
      }
    }
  },
  watch: {
    '$route.name': function () {
      console.log(this.$route.path.substring(6,13)) 

      console.log("watch")

      if(this.$route.path.substring(6,13) == 'setting') this.showSetting = true
      else this.showSetting = false
      //CompanyList, 
      if(this.getIsShowEvent) this.chgIsShowEvent(false)

      if(this.$route.name === 'DefaultDevice') {
        for(let i=0; i<this.siteList.length; i++) {
          for(let j=0; j<this.siteList[i].mapInfo.length; j++) {
            for(let k=0; k<this.siteList[i].mapInfo[j].deviceInfo.length; k++) {
              if(this.siteList[i].mapInfo[j].deviceInfo[k].deviceGuid === this.$route.query.guid) {
                this.selectedSite = i;
                this.selectedMap = j;
                this.selectedDevice = k;
              }
            }
          }
        }
      }
      else {
        this.selectedSite = -1;
        this.selectedMap = -1;
        this.selectedDevice = -1;
      }
    },
    'getDeviceFilterStatus': function () {
      this.deviceFilter = JSON.parse(this.getDeviceFilterStatus)
      this.initSideMenu();
    },
    // 'showSetting': function () {
    //   console.log("###########", this.showSetting)
    //   // if(this.showSetting) this.showSettingStr = 'active'
    //   // else this.showSettingStr = '';
    //   if(this.showSetting) this.chgSideExpand('active')
    //   else this.chgSideExpand('')
    // }
  },
}
</script>
